<template>
    <v-card>
        <v-card-title class="text-h6">
            {{ $t(`labels.${createType}`) }}
            <v-spacer></v-spacer>
            <v-btn small color="success" outlined @click="create" class="mr-2" :disabled="isDisabledBtnSave">
                {{ $t('labels.save') }}
            </v-btn>
            <v-btn small color="error" outlined @click="cancel">
                {{ $t('labels.close') }}
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row dense class="mb-3">
                <v-col cols="12" md="2">
                    <select-pos-model v-model="id_pos" :label="$t('labels.warehouse')"
                        @change="onWarehouseChange" :single-line="false"></select-pos-model>
                </v-col>
                <template v-if="createType === 'import'">
                    <v-col cols="12" md="2">
                        <v-autocomplete class="c-input-small" v-model="id_supplier" :items="supplierOptions"
                            :placeholder="$t('labels.supplier')" dense outlined hide-details></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-file-input accept="image/*" v-model="file" @change="onInputFileChange"
                            append-icon="mdi-paperclip" prepend-icon="" dense outlined hide-details
                            label="Ảnh phiếu giao hàng" class="c-input-small"></v-file-input>
                    </v-col>
                </template>
                <template v-else>
                    <v-col cols="12" md="2">
                        <v-autocomplete class="c-input-small" v-model="id_employee_receipt" :items="employees"
                            label="Chọn nhân sự" dense outlined hide-details></v-autocomplete>
                    </v-col>
                </template>
            </v-row>

            <v-simple-table fixed-header height="calc(100vh - 315px)" class="table-padding-2">
                <template v-slot:default>
                    <thead class="v-data-table-header">
                        <tr>
                            <th style="width: 150px" class="text-center">Mã vật tư</th>
                            <th class="text-center">Tên</th>
                            <th class="text-center">Đơn vị</th>
                            <th class="text-center">Kích thước</th>
                            <th class="text-center">Khả dụng</th>
                            <th class="text-center error--text" style="width: 120px">{{ $t(`labels.${createType}`) }}</th>
                            <th class="text-center" style="width: 75px">
                                <v-btn color="success" small @click="addConfig" :disabled="isDisabledBtnAdd">
                                    {{ $t('labels.add') }}
                                </v-btn>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items" :key="`${index}_${item.id_wp_packaging}`" class="text-center">
                            <td>
                                <v-autocomplete class="c-input-small" v-model="item.id_wp_packaging"
                                    :items="packagingOptions" :placeholder="$t('labels.packaging')" dense outlined
                                    hide-details single-line></v-autocomplete>
                            </td>
                            <td>{{ packaging(item).name }}</td>
                            <td>{{ packaging(item).unit }}</td>
                            <td>{{ packaging(item).dxrxc }}</td>
                            <td>{{ packaging(item).quantity }}</td>
                            <td>
                                <v-text-field class="c-input-small" v-model.number="item.quantity" type="number"
                                    :placeholder="$t('labels.import')" dense outlined hide-details
                                    single-line></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>


<script>
import { httpClient } from "@/libs/http";
import { compress } from "image-conversion";
import moment from "moment";

export default {
    name: "ImportDialog",
    props: {
        createType: {
            type: String,
            default: () => 'import'
        },
    },
    data: () => ({
        id_pos: null,
        id_supplier: null,
        id_employee_receipt: null,
        image: null,
        file: null,
        items: [],
        suppliers: [],
        packagings: [],
        employees: [],
        isLoading: false,
    }),
    computed: {
        isDisabledBtnAdd() {
            const checkItem = [...this.items].find(item => !item.id_wp_packaging || !item.quantity)
            return !!checkItem
        },
        isDisabledBtnSave() {
            return !this.id_pos ||
                (this.createType === 'import' && (!this.id_supplier || !this.image) ||
                (this.createType === 'export' && !this.id_employee_receipt)) ||
                this.isDisabledBtnAdd
        },
        packaging() {
            return item => item.id_wp_packaging ? [...this.packagings].find(utem => utem.id_wp_packaging === item.id_wp_packaging) : {}
        },
        supplierOptions() {
            if (!this.suppliers || this.suppliers.length === 0 || !this.id_pos) {
                return []
            }
            return [...this.suppliers].map(item => ({
                text: `${item.name} - ${item.code}`,
                value: item.id
            }))
        },
        packagingOptions() {
            if (!this.packagings || this.packagings.length === 0) {
                return []
            }
            return [...this.packagings].map(item => ({
                text: `${item.name} - ${item.code}`,
                value: item.id_wp_packaging
            }))
        },
    },
    methods: {
        cancel() {
            this.$emit('cancel')
        },
        resetValue() {
            this.id_supplier = null
            this.id_employee_receipt = null
            this.image = null
            this.file = null
            this.items = []
            this.packagings = []
            this.suppliers = []
            this.employees = []
        },
        onWarehouseChange() {
            this.resetValue()
            this.getEmployees()
            this.getSuppliers()
            this.getPackagings()
            this.addConfig()
        },
        onInputFileChange(file) {
            if (file) {
                this.uploadFile(file)
            }
        },
        async uploadFile(file) {
            const resBlob = await compress(file, {
                quality: 0.85,
                type: "image/jpeg",
            })
            const newFile = new File([resBlob], `${moment().unix()}_${this.generateRandomString(10)}.jpg`, { type: "image/jpeg" })
            let fd = new FormData();
            fd.append("file", newFile);
            httpClient.post(`/upload-image`, fd).then(res => {
                this.image = res.data
            }).catch(err => {
                console.log('uploadFile err', err)
            })
        },
        async getSuppliers() {
            const { data } = await httpClient.post('/packaging-get-supplier-by-pos', {
                id_pos: this.id_pos
            })
            this.suppliers = [...data]
        },
        async getPackagings() {
            const { data } = await httpClient.post('/packaging-get-packaging-by-pos', {
                id_pos: this.id_pos,
            })
            this.packagings = [...data]
        },
        async create() {
            if (this.isLoading) {
                this.$vToastify.warning(this.$t('messages.loading'));
                return false
            }
            this.isLoading = true
            try {
                await httpClient.post(`/packaging-create-${this.createType}`, {
                    id_pos: this.id_pos,
                    id_supplier: this.id_supplier,
                    id_employee_receipt: this.id_employee_receipt,
                    image: this.image,
                    items: this.items,
                })
                this.isLoading = false
                this.onWarehouseChange()
            } catch (e) {
                this.isLoading = false
                const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
                this.$vToastify.error(errMsg);
            }
        },
        addConfig() {
            if (this.isDisabledBtnAdd) {
                return false
            }
            this.items.unshift({
                id_wp_packaging: null,
                quantity: null,
            })
        },
        async getEmployees() {
            const { data } = await httpClient.post('/employee-active-my-pos', {
                id_pos: this.id_pos
            })
            const options = []
            data.forEach(item => {
            if (![39, 152, 208, 488, 805].includes(item.id_identity)) {
                    options.push({
                        value: item.id,
                        text: item.full_name || ''
                    })
                }
            })
            this.employees = [...options]
        },
    }
}
</script>